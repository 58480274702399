import { useRef } from 'react';
import { Grid, Alert, AlertTitle, Typography } from '@mui/material';
import { styled } from '@mui/system';
import scrollIntoView from 'scroll-into-view-if-needed';

import JustiFiPalette from '../Justifi/justifi-pallete';

const StyledAlert = styled(Alert)({
  backgroundColor: JustiFiPalette.red[50],
  borderLeft: `8px solid ${JustiFiPalette.red[800]}`,
  borderRadius: 0,
  color: JustiFiPalette.grey[800],
  padding: '8px',
  '& .MuiAlert-icon': {
    color: JustiFiPalette.red[800],
    marginRight: '8px',
    padding: 0
  },
  '& .MuiAlert-message': {
    padding: 0
  },
  '& .MuiAlertTitle-root': {
    marginBottom: '2px'
  }
});

const StyledAlertTitle = styled(AlertTitle)(({ theme }) => ({
  color: JustiFiPalette.red[800],
  fontWeight: 600,
  fontFamily: (theme as any).typography.fontFamily2
}));


interface CheckoutFormErrorAlertProps {
  message: string
}

function CheckoutFormErrorAlert(props: CheckoutFormErrorAlertProps) {
  const { message } = props;
  const alertRef = useRef(null);
  const alertElement = alertRef.current;

  if (alertElement) {
    scrollIntoView(alertElement, { behavior: 'smooth' });
  }

  return (
    <Grid item xs={12} ref={alertRef}>
      <StyledAlert severity="error">
        <StyledAlertTitle>Your payment didn't go through</StyledAlertTitle>
        <Typography variant="body2">{message}</Typography>
      </StyledAlert>
    </Grid>
  );
}

export default CheckoutFormErrorAlert;
