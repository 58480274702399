import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const LOGO_SIZE = '96px';
const GRID_COLUMN_SPACING = 0.5;
const StyledLogo = styled('img')({ height: LOGO_SIZE, width: LOGO_SIZE });
const StyledLogoGridItem = styled(Grid)(({ theme }) => ({
  // For proper alignment with the back arrow, we need to make sure the grid element
  // wrapping it is square, and it is (LOGO_SIZE + GRID_COLUMN_SPACING(margin)) wide
  height: `calc(${theme.spacing(GRID_COLUMN_SPACING)} + ${LOGO_SIZE})`
}));

interface BackButtonProps {
  accountName: string,
  accountLogoUrl: string,
  backUrl: string | undefined
}

function BackButton(props: BackButtonProps) {
  const { accountName, accountLogoUrl, backUrl } = props;
  const goBack = () => {
    if (backUrl) {
      window.location.href = backUrl;
    } else {
      window.history.back();
    }
  };

  const logo = () => {
    if (accountLogoUrl === '') {
      return(<></>);
    } else {
      return(
        <StyledLogoGridItem item>
          <StyledLogo
            src={accountLogoUrl}
            alt={`${accountName} logo`}
          />
        </StyledLogoGridItem>
      );
    }
  };

  return (
    <Grid container columnSpacing={GRID_COLUMN_SPACING} alignItems="center">
      <Grid item>
        <IconButton aria-label="back" onClick={goBack} >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      {logo()}
    </Grid>
  );
}

export default BackButton;
