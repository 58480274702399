import configJson from './config.json';

interface ConfigVars {
  iframeOrigin: string;
  apiUrl: string;
}

export function getConfig(): ConfigVars {
  if (process.env.REACT_APP_ENVIRONMENT) {
    return (configJson as any)[process.env.REACT_APP_ENVIRONMENT];
  }

  return configJson.development;
}

export type { ConfigVars };
