import { Typography } from '@mui/material';

import { formatCurrency } from '../Shared/FormattingUtilities';

interface CheckoutFormHeaderProps {
  amount: number,
  description: string,
  accountName: string
}

function CheckoutFormHeader(props: CheckoutFormHeaderProps) {
  const { amount, description, accountName } = props;

  return (
    <header>
      <Typography component="h1" variant="h6">Pay {accountName}</Typography>
      <Typography variant="h3">{formatCurrency(amount)}</Typography>
      <Typography component="h2" variant="caption">{description}</Typography>
    </header>
  );
}

export default CheckoutFormHeader;
