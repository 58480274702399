import { getConfig } from '../config';
import { fetchWithTimeout } from './Base';

export interface CheckoutSessionAccount {
  name: string;
  logo: string;
  primary_color: string;
  secondary_color: string;
  client_id: string;
  redirect_urls: string[];
}

export interface CheckoutSession {
  payment_intent_id: string;
  amount: number;
  description: string;
  account: CheckoutSessionAccount;
  after_payment_url: string;
  back_url: string;
  seller_account_id: string;
  email: string;
  payment: {
    ach_payments: boolean,
    credit_card_payments: boolean
  };
}

export async function getCheckoutSession(checkoutSessionId: string) {
  const { apiUrl } = getConfig();
  const requestUrl = `${apiUrl}/v1/checkout_sessions/${checkoutSessionId}`;

  return fetchWithTimeout(requestUrl, { method: 'GET' });
}
