import React from 'react';
import ReactDOM from 'react-dom';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';

import App from './App';
import reportWebVitals from './reportWebVitals';

const emotion = createCache({
  key: 'muistyles',
  prepend: true
});

const enableSentry = ['production', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT || '');

if (enableSentry) {
  Sentry.init({
    dsn: 'https://311ac2d2c2784c95ba2176e2f7bea972@o1004097.ingest.sentry.io/4504928155074565',
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <CacheProvider value={emotion}>
      <App />
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
