import { Container, Typography } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useContext, useEffect, useState } from 'react';
import { ErrorContext } from './App';
import { defaultBaseTheme } from './Justifi/theme';

const NotFoundPage = () => {
  const errorCtx = useContext(ErrorContext);
  const [errorIsNotFound, setErrorIsNotFound] = useState<boolean>(true);

  useEffect(() => {
    setErrorIsNotFound(!errorCtx.message || checkErrorIsNotFound(errorCtx.message));
  }, [errorCtx]);

  // function to check if error message related to a 404 (not found)
  const checkErrorIsNotFound = (message: string) => {
    return !!message.match(/404|not found/i)?.length;
  };

  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }}>
      <SentimentDissatisfiedIcon sx={{ width: '120px', height: '120px' }} />
      <Typography variant="h3">{errorIsNotFound
        ? 'Hmm, we couldn’t find that payment'
        : 'Hmm, something went wrong'}
      </Typography>
      <Typography>
      {errorIsNotFound
        ? `Double check the URL in your address bar. If you received a link to pay,
          try going back and following the link again.`
        : 'There was an error. Please try again.'}
      </Typography>
      {errorCtx.message && !errorIsNotFound
      ? <Typography
          variant='inherit'
          textAlign={'center'}
          color={defaultBaseTheme.palette.grey[800]}
          sx={{ marginTop: '50px' }}
        >
          Further error information: <br/>
          {errorCtx.message}
        </Typography>
      : null}
    </Container>
  );
};

export default NotFoundPage;
