import { styled } from '@mui/system';
import JustiFiPalette from '../Justifi/justifi-pallete';
import Logo from '../Shared/Logo';

const StyledBranding = styled('div')({
  alignItems: 'baseline',
  color: JustiFiPalette.grey[700],
  display: 'flex',
  fontSize: '.75rem',
  '& svg': {
    height: 'auto',
    letterSpacing: '0.4',
    marginBottom: '-8%',
    marginLeft: '4px',
    width: '80px'
  }
});
const StyledLegal = styled('div')({
  color: JustiFiPalette.grey[600],
  fontSize: '.625rem',
  letterSpacing: '0.33',
  '& a': {
    color: 'inherit'
  }
});

function CheckoutFormFooter() {
  const year = new Date().getFullYear();

  return (
    <>
      <StyledBranding>
        powered by
        <a href="https://www.justifi.ai/" target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </StyledBranding>
      <StyledLegal>
        <a href="https://www.justifi.ai/terms-and-conditions/"
          target="_blank"
          rel="noreferrer">Terms and Conditions</a>
        &nbsp;|&nbsp;
        <a href="https://www.justifi.ai/contact"
          target="_blank"
          rel="noreferrer">Contact</a>
        &nbsp;| © {year} JustiFi. All rights reserved.
      </StyledLegal>
    </>
  );
}

export default CheckoutFormFooter;
