import { v4 as uuidv4 } from 'uuid';
import { getConfig } from '../config';
import { fetchWithTimeout, MAX_ATTEMPTS } from './Base';

export async function SubmitPayment(checkoutSessionId: string, token: string) {
  const idempotencyKey = `capture-${uuidv4()}`;

  for (let attempts = 0; attempts < MAX_ATTEMPTS; attempts++) {
    try {
      const response = await makePaymentRequest(
        checkoutSessionId,
        token,
        idempotencyKey
      );
      const shouldRetryHeader = response.headers.get('Should-Retry');
      if (shouldRetryHeader === 'true') continue;
      return { success: response.ok, body: await response.json() };
    } catch (error: any) {
      if (error.name === 'AbortError') continue;
      throw error;
    }
  }

  return {
    body: {
      error: { message: 'Request timed out' }
    }
  };
};

async function makePaymentRequest(
  checkoutSessionId: string,
  token: string,
  idempotencyKey: string
) {
  const { apiUrl } = getConfig();
  const requestUrl = `${apiUrl}/v1/checkout_sessions/${checkoutSessionId}/pay`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Idempotency-Key': idempotencyKey,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'token': token })
  };
  return fetchWithTimeout(requestUrl, requestOptions);
}
