import { styled } from '@mui/system';

const StyledForm = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  marginTop: 0,
  marginRight: 'auto',
  marginBottom: 0,
  marginLeft: 'auto',
  maxWidth: '496px',
  minHeight: '100vh',
  padding: theme.spacing(2)
}));

export default StyledForm;
