export const MAX_ATTEMPTS = 3;
export const TIMEOUT_MS = 30000;

export interface ApiResponse<T> {
  id: number;
  data: T;
  type: string;
  page_info: any;
}

export async function fetchWithTimeout(requestUrl: string, options: any) {
  const controller = new AbortController();
  const timer = setTimeout(() => controller.abort(), TIMEOUT_MS);

  const response = await fetch(requestUrl, {
    ...options,
    signal: controller.signal
  });

  clearTimeout(timer);

  return response;
}
