import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { JustifiPaymentForm } from '@justifi/react-components';
import BackButton from './BackButton';
import CheckoutFormHeader from './CheckoutFormHeader';
import CheckoutFormFooter from './CheckoutFormFooter';
import CheckoutFormErrorAlert from './CheckoutFormErrorAlert';
import { CheckoutSession, getCheckoutSession } from '../Api/CheckoutSession';
import { ApiResponse } from '../Api/Base';
import createThemeWithBaseTheme, { defaultBaseTheme } from '../Justifi/theme';
import StyledForm from './StyledForm';
import { ErrorContext } from '../App';
import { getConfig } from '../config';
import { SubmitPayment } from '../Api/SubmitPayment';

const config = getConfig();

interface CheckoutProps {
  checkoutSessionId: string;
}

function CheckoutForm(props: CheckoutProps) {
  const { checkoutSessionId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>();
  const [theme, setTheme] = useState<Theme>(defaultBaseTheme);
  const [serverError, setServerError] = useState<string>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const navigate = useNavigate();

  const errorCtx = useContext(ErrorContext);

  useEffect(() => {
    // Fix React warning about updating unmounted component, reset when unnmounted (see return)
    let isMounted = true;
    const loadSession = async () => {
      let session: ApiResponse<CheckoutSession>;
      try {
        const checkoutSessionResponse = await getCheckoutSession(
          checkoutSessionId
        );
        if (!checkoutSessionResponse.ok) {
          throw new Error(
            checkoutSessionResponse?.statusText || 'Unknown error'
          );
        }

        session = await checkoutSessionResponse.json();
      } catch (e) {
        if (isMounted) {
          errorCtx.setMessage(String(e));
        }
        return navigate('/404');
      }
      if (session.data) {
        setCheckoutSession(session.data);

        const accountOptions = session?.data?.account;
        if (accountOptions) {
          try {
            const extendedTheme = createThemeWithBaseTheme({
              palette: {
                primary: {
                  main:
                    accountOptions.primary_color ||
                    defaultBaseTheme.palette.primary.main,
                },
                secondary: {
                  main:
                    accountOptions.secondary_color ||
                    defaultBaseTheme.palette.secondary.main,
                },
              },
            });

            setTheme(extendedTheme);

            // I'm sorry
            document.title = `Check out for ${session?.data?.description || '...'
              }`;
            document.body.style.background =
              extendedTheme.palette.primary.main || 'white';
            // end apology
          } catch (e) {
            if (isMounted) {
              errorCtx.setMessage(String(e));
            }
            return navigate('/404');
          }
        }

        setIsLoading(false);
      }
    };

    loadSession();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSessionId]);

  const handleSubmitted = useCallback(
    async (response: any) => {
      if (response?.detail?.error) {
        setServerError(
          response?.detail?.error?.message || response?.detail?.error
        );
        return;
      }
      const { card, bank_account } = response?.detail?.data;
      const token = card?.token || bank_account?.token;
      const payment = await SubmitPayment(checkoutSessionId, token);
      if (payment.success) {
        window.location.href = checkoutSession?.after_payment_url || '';
      } else {
        setServerError(payment?.body?.error?.message || payment?.body?.error);
      }
    },
    [checkoutSession?.after_payment_url, checkoutSessionId]
  );

  function renderLoadingSkeleton() {
    return (
      <Box pl={4}>
        <Skeleton variant="circular" width={100} height={100} sx={{ mt: 6 }} />
        <Skeleton variant="text" sx={{ mt: 3 }} />
        <Skeleton variant="text" sx={{ mt: 1 }} />
        <Skeleton
          variant="rectangular"
          width={isMdUp ? 400 : 300}
          height={120}
          sx={{ mt: 3 }}
        />
        <Skeleton
          variant="rectangular"
          width={isMdUp ? 400 : 300}
          height={140}
          sx={{ mt: 3 }}
        />
        <Skeleton
          variant="rectangular"
          width={isMdUp ? 400 : 300}
          height={60}
          sx={{ mt: 3 }}
        />
      </Box>
    );
  }

  function renderForm() {
    return (
      <StyledForm>
        <Grid container spacing={3}>
          {isLoading ? (
            renderLoadingSkeleton()
          ) : (
            <>
              <style>
                {`:root {
                  --jfi-primary-color: ${checkoutSession?.account.primary_color || '#212529'};
                  --jfi-submit-button-width: 100%;
                }`}
              </style>
              {checkoutSession?.back_url && (
                <Grid item xs={12}>
                  <BackButton
                    accountLogoUrl={checkoutSession?.account?.logo || ''}
                    accountName={checkoutSession?.account?.name || ''}
                    backUrl={checkoutSession?.back_url || ''}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <CheckoutFormHeader
                  amount={checkoutSession?.amount || 0}
                  description={checkoutSession?.description || ''}
                  accountName={checkoutSession?.account?.name || ''}
                />
              </Grid>

              {serverError && (
                <CheckoutFormErrorAlert message={serverError || ''} />
              )}

              <Grid item xs={12}>
                <JustifiPaymentForm
                  iframeOrigin={config.iframeOrigin}
                  card={checkoutSession?.payment.credit_card_payments}
                  bankAccount={checkoutSession?.payment.ach_payments}
                  email={checkoutSession?.email}
                  clientId={checkoutSession?.account.client_id}
                  accountId={checkoutSession?.seller_account_id}
                  submitButtonText="Pay"
                  onSubmitted={handleSubmitted}
                />
              </Grid>

              <Grid item xs={12}>
                <CheckoutFormFooter />
              </Grid>
            </>
          )}
        </Grid>
      </StyledForm>
    );
  }

  return <ThemeProvider theme={theme}>{renderForm()}</ThemeProvider>;
}

export default CheckoutForm;
