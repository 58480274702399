import { deepmerge } from '@mui/utils';
import { createTheme, ThemeOptions } from '@mui/material/styles';

import JustiFiPalette from './justifi-pallete';

const fallbackFonts = '"Helvetica Neue", Arial, sans-serif';
const fontFamily1 = `Lato, ${fallbackFonts}`;
const fontFamily2 = `neue-haas-grotesk-display, ${fallbackFonts}`;

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontFamily2: React.CSSProperties['fontFamily'];
    code1: {
      fontFamily: React.CSSProperties['fontFamily'],
      fontSize: React.CSSProperties['fontSize'],
      lineHeight: React.CSSProperties['lineHeight']
    },
    code2: {
      fontFamily: React.CSSProperties['fontFamily'],
      fontSize: React.CSSProperties['fontSize'],
      lineHeight: React.CSSProperties['lineHeight']
    }
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    fontFamily2?: React.CSSProperties['fontFamily'];
  }
}

// Base Justifi theme for the checkout
const baseTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontFamily: fontFamily1,
    fontFamily2: fontFamily2,
    h1: {
      fontFamily: fontFamily2,
      fontWeight: 700
    },
    h2: {
      fontFamily: fontFamily2,
      fontWeight: 700
    },
    h3: {
      fontFamily: fontFamily2,
      fontWeight: 700
    },
    h4: {
      fontFamily: fontFamily2,
      fontWeight: 700
    },
    h5: {
      fontFamily: fontFamily2,
      fontWeight: 700
    },
    h6: {
      fontFamily: fontFamily2,
      fontWeight: 400
    },
    caption: {
      color: JustiFiPalette.grey[700],
      lineHeight: 1.333
    },
    overline: {
      letterSpacing: '.0625rem'
    },
    subtitle1: {
      fontFamily: fontFamily2,
      fontWeight: 600
    }
  },
  palette: {
    mode: 'light',
    background: {
      default: JustiFiPalette.grey[100]
    },
    primary: {
      main: JustiFiPalette.yellow[500]
    },
    secondary: {
      main: JustiFiPalette.yellow[100]
    },
    grey: {
      500: JustiFiPalette.grey[500]
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '1px',
          fontFamily: fontFamily2,
          fontSize: '16px',
          fontWeight: 600,
          letterSpacing: 1.5,
          lineHeight: '20px',
          minWidth: '64px',
          padding: '18px',
          textTransform: 'uppercase'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: JustiFiPalette.grey[600],
          '&.Mui-checked': {
            color: JustiFiPalette.grey[900]
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: JustiFiPalette.grey[700],
          '&.Mui-focused': { color: JustiFiPalette.grey[900] },
          '&.Mui-error': { color: JustiFiPalette.red[800] },
          '&.Mui-error.Mui-focused': { color: JustiFiPalette.red[800] },
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: JustiFiPalette.blue[800],
          textDecorationColor: JustiFiPalette.blue[800],
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '1px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: JustiFiPalette.grey[800],
          borderRadius: '1px',
          padding: '6px 8px',
          '&.MuiTooltip-tooltipPlacementBottom': {
            margin: '8px 0'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb': {
            background: JustiFiPalette.grey[200]
          },
          '& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track': {
            background: JustiFiPalette.grey[900]
          },
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': { backgroundColor: JustiFiPalette.grey[100] },
          '& .MuiFilledInput-root:hover': { backgroundColor: JustiFiPalette.grey[200] },
          '& .MuiFilledInput-root.Mui-focused': { backgroundColor: JustiFiPalette.grey[100] },
          '& .MuiFilledInput-root.Mui-error:after': { borderBottomColor: JustiFiPalette.red[800] }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': { color: JustiFiPalette.red[800] }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily2,
          letterSpacing: '0.1rem'
        }
      }
    }
  }
};

const defaultBaseTheme = createTheme(baseTheme);

type IAccountSettings = {
  palette: {
    primary: {
      main: string
    },
    secondary: {
      main: string
    }
  }
}

// Exported function to merge account settings into a theme, overriding the default
const createThemeWithBaseTheme = (
  accountSettings?: IAccountSettings
): ReturnType<typeof createTheme>  => {
  return createTheme(deepmerge(baseTheme, accountSettings));
};

export { defaultBaseTheme };

export default createThemeWithBaseTheme;
