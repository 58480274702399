/* eslint-disable max-len */
function Logo() {
  return (
    <svg x="0px" y="0px" viewBox="0 0 1034.1 409.1" xmlSpace="preserve" aria-labelledby="justifi-logo-title">
      <title id="justifi-logo-title">JustiFi logo</title>
      <g>
        <path fill="#665E57" d="M277.2,313.7c-8.1,13.3-25.8,18.9-41.3,18.9c-37.5,0-58.6-27.3-58.6-60.2V175h47.2v87.5
	c0,14.9,8.1,26.7,24.8,26.7c15.8,0,25.7-10.9,25.7-26.4V175h47.2v126.6c0,14,1.2,25.1,1.6,27.6h-45.3
	C277.8,326.4,277.2,317.7,277.2,313.7z"/>
        <path fill="#665E57" d="M381.8,278.3c0.6,10.5,8.1,20.8,24.2,20.8c12.1,0,18-6.5,18-13.3c0-5.6-3.7-10.2-15.2-12.7l-17.7-4
	c-32.9-7.2-45.9-25.8-45.9-47.6c0-28.2,24.8-51.2,59.3-51.2c44.7,0,60.2,27.9,61.7,45.9l-39.1,7.1c-1.2-10.2-7.8-18.9-22-18.9
	c-9,0-16.8,5.3-16.8,13.3c0,6.5,5.3,10.2,12.1,11.5l20.5,4c32,6.5,47.5,25.8,47.5,48.4c0,26.4-20.2,52.1-61.4,52.1
	c-48.4,0-63.9-31.3-65.2-48.4L381.8,278.3z"/>
        <path fill="#665E57" d="M546.2,175h30.1v41.3h-30.1V274c0,12.7,6.8,16.1,17.1,16.1c5,0,9.6-0.9,12.4-1.5v39.1
	c-1.9,0.9-9.6,4.3-24.5,4.3c-32,0-51.5-18.9-51.5-49.6v-66.1h-27.3V175h7.8c16.1,0,23.9-10.9,23.9-25.1V130h42.1V175z"/>
        <path fill="#665E57" d="M623.8,91.2c15.5,0,27.9,12.4,27.9,27.6c0,15.2-12.4,27.6-27.9,27.6c-14.9,0-27.3-12.4-27.3-27.6
	C596.5,103.7,608.9,91.2,623.8,91.2z M600.5,329.2V175h47.2v154.2H600.5z"/>
        <path fill="#665E57" d="M925.5,109.2c15.5,0,27.9,12.4,27.9,27.6s-12.4,27.6-27.9,27.6c-14.9,0-27.3-12.4-27.3-27.6
	S910.6,109.2,925.5,109.2z M902.2,329.2V175h47.2v154.2H902.2z"/>
        <g>
          <path d="M879.4,120.9c-2.4-1.4-3.8-4-4-6.7l-1.8-36.7l-3.1,0.1l0.2-3.1L734.2,67l-2.8-1l-0.3,0.8H731V67l-0.9,2.7l-3.2,1.3
		l0.1,0.2l-0.7,0.1l5.8,56.3c0.2,2-1.7,3.5-3.6,2.9l-38.1-12.8l-1.1,3.2l-3.2-0.2l-5.4,81.4c-0.3,3.8-2.2,7.5-5.4,9.4
		c-4.5,2.6-7.5,7.5-7.5,13.1c0,6.2,3.7,11.5,9,13.9c3.6,1.6,6.4,4.6,7.2,8.4l14.8,56.2l7.1-1.4l-14.7-57.4c-1.1-6.2,2-4.3,4.4-2.2
		l44.3,44.8c2,2,2.7,4.8,2.3,7.6c-0.1,0.5-0.1,1.1-0.1,1.6c0,0.6,0,1.2,0.1,1.8c0.4,2.5-0.7,5-2.5,6.8l-46.6,37l5,5.1l46.1-36.5
		c2-2,4.9-2.8,7.7-2.2c0.4,0.1,0.9,0.1,1.4,0.1l0,0c3.3-0.1,5.4,2.6,6.2,4l0.1,0.2c0.2,0.4,0.3,0.6,0.3,0.6v-0.1l18.7,30.8l6.1-3.7
		l-17.4-28.5c-2.1-3.5-3.1-7.8-1.9-11.7c0.3-1.1,0.5-2.3,0.5-3.5c0-1.5-0.3-3-0.8-4.4c-1.4-3.7-1-7.8,0.9-11.2l27.9-50.5l49.6,0.2
		c1.8,0,3.4,1,4.5,2.4c1.7,2.2,4.3,3.6,7.3,3.6c5.1,0,9.2-4.1,9.2-9.2s-4.1-9.2-9.2-9.2l0,0l0,0c0,0-3.8,0.9-9.5-3.7l-70.3-61.8
		c-2.8-3.4,0.7-5,3-5.6l39.7-6.1v0.1l31.1-2.6c4.7-0.4,9.1,1.7,12.4,5.1c3.2,3.3,8,5.2,13.2,4.2c5.8-1.1,10.5-5.8,11.5-11.6
		C887.5,129.6,884.4,123.8,879.4,120.9z M763.9,75.8l77.2,4.2c2,0.1,2.4,2.9,0.5,3.6l-21.9,7.1c-1.7,0.6-3.6,0.2-5.1-0.7
		c-1.2-0.7-2.6-1.1-4.1-1.1s-2.9,0.4-4.1,1.1c-1.9,1.1-4.2,1-6.2,0.3l-36.5-12.9C762.7,77.1,762.9,75.8,763.9,75.8z M797.7,97.1
		c0,0,5.6,2,1.5,6.4l-27.4,26.8v-0.1c-7.3,7.4-11.4,2.4-12.9-0.5l-22.4-54.2L797.7,97.1z M746.1,144.1c0.9,0.4,3.9,1.9,4.4,4.5
		c0,0,0,0,0,0.1c0.3,1.5-0.2,3.2-2.3,5.4c0,0,0.1,0,0.2-0.1l-2.4,2.4c-1.3,1.3-3.5,0.5-3.7-1.3l-0.9-8.5c-0.2-2,1.7-3.5,3.6-2.9
		L746.1,144.1z M737.8,97.4l14.4,34.9c2.1,5.3-0.5,5.8-2.9,5.3l-2.8-0.9c-0.1-0.1-0.2-0.1-0.2-0.1l0,0l-0.6-0.2
		c-3.5-1.2-6-4.3-6.4-8l-3.2-30.6C736.2,96.8,737.4,96.5,737.8,97.4z M706.8,221.4L706.8,221.4c-13.1,0.3-6.8-8.4-4.7-10.9
		l29.5-29.6c2.2-2.2,6-0.9,6.3,2.2l3,28.8c0.6,5.4-3.7,10.1-9.2,10L706.8,221.4z M693,126.2l34.6,11.6c3.5,1.2,6,4.3,6.4,8
		l1.6,15.4c0.3,3.1-0.8,6.2-3,8.4l-35.2,35.3l0.2-0.3c-8.7,9.1-9.8,2.4-9.8-1.6L693,126.2z M744.7,281.7
		c-9.3-9.4-33.5-33.8-43.9-44.3c-5.7-7.3,1.3-8.6,4.6-8.8l29.2,0.6c4.6,0.1,8.3,3.6,8.8,8.1l4.4,42.9
		C748.1,282,746,283,744.7,281.7z M758.5,279.1c-0.9,1.6-3.3,1.1-3.5-0.7l-4-38.7c-0.6-5.4,3.7-10.1,9.2-10l25.5,0.5
		C785.6,230.3,766,265.6,758.5,279.1z M757.1,222.5c-4.6-0.1-8.3-3.6-8.8-8.1l-4.2-40.6c-0.3-3.1,0.8-6.2,3-8.4l5-5v0.1
		c0,0,0-0.1,0.1-0.2l0.6-0.6c1.1-1.1,3.5-2.7,6.7-1.6c2.2,0.8,3.6,2.5,4.3,4.1l0.9,2.4c0.1,0.3,0.1,0.6,0.1,0.6v-0.3l21.6,58.3
		L757.1,222.5z M830.5,223.1l-36.4-0.2l-22.8-61.6c-0.2-1.3-0.1-2.5,1.8-1.1l60.9,53.6C837.8,217,835.4,223.1,830.5,223.1z
			M846,131.4l-28.2,2.3v-0.1l-39,6l0.2-0.1c-0.3,0.1-0.5,0.1-0.8,0.2l-1.3,0.2c-3.9,0.4-2.5-1.9-1.1-3.5l29.7-29.1l0,0
		c0,0,0,0,0.1-0.1l0.2-0.2c0.6-0.6,2.3-1.9,4.4-1.5l0,0c0.1,0,0.1,0,0.2,0c0.5,0,1.1-0.1,1.6-0.2c1.7-0.3,3.4-0.2,4.8,0.7
		l30.7,19.5C850,127.3,849,131.2,846,131.4z M864.2,121.2c-0.2,0.1-0.3,0.2-0.5,0.3c-2.8,2-6.6,1.8-9.5-0.1l-32.3-20.5
		c-1.3-0.9-1.1-2.9,0.4-3.4c11.3-3.8,44.4-14.6,44.4-14.6l1.5,30.7C868.4,116.7,866.8,119.5,864.2,121.2z"/>
          <path d="M811,290c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S814.3,290,811,290z" />
          <path d="M671.2,163.2c0-3.3-2.7-6-6-6s-6,2.7-6,6s2.7,6,6,6S671.2,166.5,671.2,163.2z" />
          <path d="M795.1,57.7c2.2,0,3.9-1.8,3.9-3.9s-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9S792.9,57.7,795.1,57.7z" />
          <path d="M711.1,388.3c-5.7,0-10.4,4.6-10.4,10.4c0,5.7,4.6,10.4,10.4,10.4c5.7,0,10.4-4.6,10.4-10.4
		C721.5,393,716.8,388.3,711.1,388.3z"/>
          <path d="M685.1,55.7c7.7,0,13.9-6.2,13.9-13.9s-6.2-13.9-13.9-13.9s-13.9,6.2-13.9,13.9S677.4,55.7,685.1,55.7z" />
          <path d="M932.4,0c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S935.7,0,932.4,0z" />
          <path fill="#665E57" d="M867.4,54.4c-10.7,1.3-19.4,10.1-20.7,20.8c-0.3,2.6-0.2,5.1,0.3,7.5c1.6,7.8-1,15.9-6.3,21.9l-10,11.4
		l0.1-0.2c0,0-0.3,0.3-0.6,0.8l-2,2.3c-2.7,2.8-7,6.5-11,6.3c-4-0.4-9.2-2.2-12.1-4.5L759,85.3c-4.6-3.5-7.3-8.9-7.5-14.7v-0.3
		c-0.2-10.8-8.3-20.3-19-21.3c-12.4-1.1-22.8,8.6-22.8,20.8c0,1.8,0.2,3.5,0.7,5.1c1.5,5.6,0.5,11.6-3.1,16.1l-11.9,15.2
		c-2,2.6-4.9,4.2-8.1,4.8c-2.5,0.5-4.8,1.6-7.2,5.3c-0.9,1.4-1.2,3.7-1.1,5.3c0.2,5,3.6,9.2,8.3,10.5h-0.1c6.6,1.3,10.4,4.3,12.2,6
		l1,1.1l0.1,0.1l0,0l43.6,45.3c9.9,10.3,14.4,24.6,12.7,38.8c-0.1,1.3-0.2,2.6-0.2,4c0,1.9,0.2,3.8,0.5,5.7
		c1.6,9.4-2.5,19-9.8,25.1l-34.4,29.1l0.1-0.1c0,0-0.2,0.2-0.6,0.5l-1.9,1.6c-2.2,1.7-5.8,4-9.6,4.1l0.1,0.1
		c-4.4,0.8-7.7,4.6-7.7,9.2c0,0.3,0.1,0.7,0.1,1l-0.2,0.2l0.3,0.1c0.6,4.6,4.5,8.1,9.3,8.1c0.5,0,1,0,1.4-0.1
		c3.1-0.5,6.3-0.4,9.1,0.9l36,19.8c2.8,1.3,2,5.4-1.1,5.5l-32.5,0.7c-4.5,0.1-9.1-0.9-12.6-3.7c-2.9-2.3-6.9-3.3-11.1-2
		c-3.8,1.1-6.8,4.3-7.9,8.1c-2.2,8.1,3.8,15.4,11.6,15.4c2.9,0,5.5-1,7.6-2.7c3.7-3,8.1-5.1,12.9-5.2l39.8-0.9
		c5.5-0.2,10.8,2.1,14.6,6.1c3.1,3.3,7.5,5.3,12.4,5.3c7.9,0,14.5-5.4,16.5-12.7l0.3-1.2l0.1-1.1c0.1-0.8,0.2-1.5,0.2-2.3
		c0-9.5-7.7-17.2-17.2-17.2c-2.4,0-4.7,0.5-6.8,1.4c-5.2,2.3-11,2.6-16.2,0.3l-41.5-21.8h0.9c-6.3-2.8-2-7.9-0.5-9.4l35.4-29.9
		c7.5-6.3,17.7-7.8,27.1-5c4.6,1.4,9.5,1.9,14.7,1.2c15.5-2,27.9-14.8,29.6-30.3c2.3-20.8-13.9-38.5-34.3-38.5c-1,0-2.1,0-3.1,0.1
		c-13.5,1.2-26.6-4.6-36-14.4l-46.2-48c-1.7-2-4-5.3-4.3-8.8c0-0.2,0-0.3,0-0.5c0-0.4,0-0.7-0.1-1.1c-0.3-3.4,0.8-6.8,2.9-9.5
		l10.4-13.3c3.6-4.6,9.3-7,15.2-6.8c0.2,0,0.4,0,0.6,0c1.8,0,3.5-0.2,5.1-0.7c6-1.6,12.4-0.7,17.3,3.1l46.6,35.8
		c3.4,2.6,6.2,6.2,7.2,10.4c0.4,1.7,1.3,3.5,3,5.5c2.3,2.6,5.5,4.5,9,4.5c6.6-0.1,11.9-5.5,11.9-12.1v-0.1c0-3.4,1.8-8.3,4.1-10.9
		l13.4-15.3c5.5-6.3,13.6-9.2,22-9.1c2.4,0,4.8-0.3,7.4-1.1c8.4-2.6,14.9-9.7,16.5-18.3C897,66.5,883.5,52.4,867.4,54.4z"/>
        </g>
        <path fill="#665E57" d="M0,245.2l46.5-8.1v20.2c0,19.5,12.4,28.5,26.7,28.5c16.1,0,25.7-11.8,25.7-28.2V116.8h49v142
	c0,40-30.7,73.5-74.4,73.5C30.1,332.3,0,303.5,0,259.7L0,245.2L0,245.2z"/>
        <g>
          <path fill="#665E57" d="M999.4,313v16.2h-6V313h-5.9v-5.4h17.8v5.4H999.4z M1028.3,329.2V316l-4.9,13.2h-4.5l-4.9-12.9v12.9h-5.9
		v-21.6h8.4l4.7,11.5l4.5-11.5h8.4v21.6H1028.3z"/>
        </g>
      </g>
    </svg>
  );
};

export default Logo;