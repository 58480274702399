import { styled } from '@mui/system';
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import NotFoundPage from './NotFound';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import { createContext, useState } from 'react';

const StyledCheckoutFormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    padding: '0 12px'
  }
}));

const CheckoutFormWrapper: React.FC = () => {
  const { checkoutSessionId } = useParams();

  return (
    <StyledCheckoutFormWrapper>
      <CheckoutForm checkoutSessionId={checkoutSessionId as string} />
    </StyledCheckoutFormWrapper>
  );
};

export interface IErrorContext { message: string, setMessage: Function };

// Create a React Context to store the global error for the Not Found Page
export const ErrorContext = createContext<IErrorContext>({
  message: '',
  setMessage: () => {}
});
ErrorContext.displayName = 'ErrorContext';

function App() {
  const [globalError, setGlobalError] = useState<string>('');

  return (
    <BrowserRouter>
      <ErrorContext.Provider value={{ message: globalError || '', setMessage: setGlobalError }}>
        <Routes>
          <Route path="/:checkoutSessionId" element={<CheckoutFormWrapper />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </ErrorContext.Provider>
    </BrowserRouter>
  );
}

export default App;
