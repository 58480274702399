import Dinero from 'dinero.js';

export function formatCurrency(amount?: number, defaultNegative: boolean = false) {
  if (!amount) amount = 0;
  if (defaultNegative && amount !== 0) amount = -amount;

  function format(amount: number): string {
    return Dinero({ amount: amount, currency: 'USD' }).toFormat('$0,0.00');
  }

  return (amount < 0) ? `(${format(-amount)})` : format(amount);
};
