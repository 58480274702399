const JustiFiPalette = {
  green: {
    50: '#E0F1EF',
    100: '#B3DED7',
    200: '#82C9BD',
    300: '#53B3A3',
    400: '#32A290',
    500: '#1D927E',
    600: '#1A8571',
    700: '#167562',
    800: '#136554',
    900: '#0C4939'
  },
  red: {
    50: '#FEEBEE',
    100: '#FDCCD1',
    200: '#EC9999',
    300: '#E17272',
    400: '#EA524F',
    500: '#EF4135',
    600: '#E03834',
    700: '#CE2E2E',
    800: '#C12727',
    900: '#B21C1B'
  },
  blue: {
    50: '#E1F4F9',
    100: '#B4E2EF',
    200: '#85CFE6',
    300: '#5DBCDC',
    400: '#44AFD6',
    500: '#31A1D1',
    600: '#2A94C3',
    700: '#2282B1',
    800: '#1F709D',
    900: '#15527B'
  },
  yellow: {
    50: '#FEF8E2',
    100: '#FDEDB5',
    200: '#FDE286',
    300: '#FCD755',
    400: '#FCCC32',
    500: '#FCCC32',
    600: '#FCB616',
    700: '#FCA412',
    800: '#FB930F',
    900: '#FB7508'
  },
  grey: {
    50: '#FAFAFC',
    100: '#F4F4F6',
    200: '#EEEEF5',
    300: '#DDDDE6',
    400: '#BABAC3',
    500: '#9B9BA3',
    600: '#72727A',
    700: '#5E5E66',
    800: '#3F3F47',
    900: '#1E1E25'
  },
};

export default JustiFiPalette;